import { ym } from './ym';
import { scroller } from 'react-scroll';

import { FORM_ID } from 'constants/global';

export function goToForm() {
  ym('goto_orderform');
  scroller.scrollTo(FORM_ID, {
    duration: 600,
    smooth: true,
    offset: -110,
  });
}
